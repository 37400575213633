import React, { useEffect,useState,useRef } from 'react'
import moment from 'moment'
import {  withApollo } from 'react-apollo';
import { Table,Row,Col,Select,Input,Button,Icon,Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import getInternalDump from '../../queries/getInternalDump'
import getRenewalCount from '../../queries/getRenewalCount'
import getInternalUsers from '../../queries/getInternalUsers'
import SiteLogs from '../deploymentDashboard/siteLogs'

import App from '../../App'
import axios from 'axios';
import { returnSymbol } from '../../utils/returnSymbol';


function myDashboard(props) {
    const [renewalRecord,setrenewalRecord] = useState([])
    const [allrenewalRecord,setallrenewalRecord] = useState([])
    const [betweenDates,setbetweenDates] = useState([])
    const [InternalUserData,setInternalUserData] = useState([])
    const [loading, setLoading] = useState(false);
    const [filterloading, setfilterloading] = useState(false);
    const [visible, setvisible] = useState(false);
    const [siteData, setsiteData] = useState(new Object());
    const [supportAccM, setsupportAccM] = useState(undefined);
    const [salesPerson, setsalesPerson] = useState(undefined);
    const [partnerType, setpartnerType] = useState(undefined);
    const [partnerName, setpartnerName] = useState(undefined);
    const [companyName, setcompanyName] = useState(undefined);
    const [searchText,setSearchText] = useState('')
    const searchInputRef = useRef(null);
    useEffect(() => {
     
      setLoading(true)
      callPromise()
    }, [props])

    async function  callPromise(){

        const currentDate = moment().subtract(1, "days")
        const pastDate = moment().subtract(10, "days");
        const toDate = moment().add(30, "days");
        let respDate = getTimeStamp(pastDate, moment().subtract(15, "days"))
        // console.log("respDate",respDate)
        let variables={
            "fromDate": respDate.endTimestamp,
            "toDate": toDate.format('YYYY-MM-DD'),
            "pastDate": respDate.startTimestamp,
            "channelPartner": props.userData.companyName

            
        }
        const startDate = moment(pastDate.format('DD-MM-YYYY'), 'DD-MM-YYYY');
        const endDate = moment(currentDate.format('DD-MM-YYYY'), 'DD-MM-YYYY');
        
        // Create an array to store the between dates
        let betweenDates = [];
        
        // Clone the start date for iteration
        let currentDate1 = startDate.clone();
        
        // Iterate through the dates and add them to the array
        while (currentDate1.isSameOrBefore(endDate, 'day')) {
          betweenDates.push(currentDate1.format('DD-MM-YYYY'));
          currentDate1.add(1, 'day');
        }
        betweenDates.reverse()
        // console.log("betweenDates",betweenDates)
        let users = await getUsers()
        setbetweenDates(betweenDates)
        setInternalUserData(users)
      //  let dump = await checkDump()
      let dump = false
       if(dump && dump.getInternalDump && dump.getInternalDump.dumpData && dump.getInternalDump.dumpData != '[]'){
      // if(false){
        let sdf = JSON.parse(dump.getInternalDump.dumpData)
        
         sdf = sdf && sdf.map((item, index) => ({
          ...item,
          serialNumber: index + 1,
        }));
      
        setrenewalRecord(sdf)
        setallrenewalRecord(sdf)
        setLoading(false)
       }else{
        let record  = await getRenewalData(variables)
        if(record.result ){
        let json = JSON.parse(record.result )
          
          json = json && json.map((item, index) => ({
            ...item,
            serialNumber: index + 1,
          }));
          
        setrenewalRecord(json)
        setallrenewalRecord(json)
        setLoading(false)
      }else{
        setLoading(false)
      }
       }
    }
    const checkDump=()=>{
        return new Promise((resolve,reject)=>{
        const currentDate = moment().format('YYYY-MM-DD');
        // console.log("currentDate::",currentDate)
        props.client.query({
            query: getInternalDump,
            variables: {
                typeValue: props.userData.userRole == "CP" ? 'renewal_dashboard::'+  props.userData.companyName : 'renewal_dashboard',
                dumpDate: currentDate,
               
          },
            fetchPolicy: 'no-cache'
        }).then(({ data }) => {
          // let record =  JSON.parse(data)
            //  console.log("getInternalDump::",data)

            resolve(data)
        })
    })
    }
    const getRenewalData=(param)=>{
      // console.log("param :::", param)
      return new Promise((resolve,reject)=>{
        axios({
          method: "GET",
          url: `https://analyser.mysnaptrade.com/renewal_dashboard`,
          params: param,
        })
          .then(function ({data}) {
            resolve(data)
          })
          .catch(function (error) {
            console.log("In Error :", error);
            resolve([])
          });
      })
  }
    const getRenewal=(variables)=>{
        // console.log("variables",variables)
        return new Promise((resolve,reject)=>{
            props.client.query({
                query: getRenewalCount,
                variables: variables,
                fetchPolicy: 'no-cache'
            }).then(({ data }) => {
                console.log("getRenewalCount::",data)
                resolve(data)
            })
        })

    }
    const getTimeStamp = (fromDate, toDate) => {
        // console.log("getTimeStamp ::",fromDate, toDate)
        fromDate.set({ hour: 0, minute: 1, second: 0, millisecond: 0 });
        toDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
     
        const startTimestamp = Math.floor(fromDate.valueOf() / 1000);
        const endTimestamp = Math.floor(toDate.valueOf() / 1000);
        // console.log("{startTimestamp, endTimestamp} :::", {startTimestamp, endTimestamp})
        return { startTimestamp, endTimestamp };
       }
    const getUsers=()=>{
        return new Promise((resolve,reject)=>{
        props.client.query({
            query: getInternalUsers,
            fetchPolicy: 'no-cache'
        })
            .then(({ data }) => {
            resolve(data.getInternalUsers)
            })
        })
    }
    const supportAccMChange =(value)=>{
        setsupportAccM(value)
    }
    const salesPersonChange =(value)=>{
        setsalesPerson(value)
    }
    const partnerTypeChange =(value)=>{
        setpartnerType(value)
    }
    const partnerNameChange =(value)=>{
        setpartnerName(value)
    }
    const companyNameChange =(e)=>{
        setcompanyName(e.target.value)
    }
    const handleFilter=()=>{
        let filterData = allrenewalRecord
        let final = []
        setfilterloading(true)
        filterData.map((ele)=>{
            let companyNameSlug = ele.companyName.toLowerCase()
            if(supportAccM && ele.supportAccManager == supportAccM){
                final.push(ele)
            }
            if(salesPerson && ele.salesPerson == salesPerson){
                final.push(ele)
            }
            if((partnerType && ele.partnerType == partnerType) || (partnerName && ele.channelPartner == partnerName)){
                final.push(ele)
            }
            // if(partnerName && ele.channelPartner == partnerName){
            //     final.push(ele)
            // }
            if(companyName && companyNameSlug.includes(companyName.toLowerCase())){
                final.push(ele)
            }
        })
        setrenewalRecord(final)
        setfilterloading(false)

    }
    const resetFilter=()=>{
        setsupportAccM(undefined)
        setsalesPerson(undefined)
        setpartnerType(undefined)
        setpartnerName(undefined)
        setcompanyName(undefined)
        setrenewalRecord(allrenewalRecord)
    }
    const openNotesModal=(data)=>{
        setvisible(true)
        setsiteData(data)
    }
    const closeNotesModal=()=>{
        setvisible(false)
    }

    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      setSearchText(selectedKeys[0])
      // this.setState({ searchText: selectedKeys[0] });
    }

   const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('')
      // this.setState({ searchText: '' });
    }

    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInputRef.current.select());
        }
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ),
    })

    const columns=[ 
      {
        title: 'Serial Number',
        dataIndex: 'serialNumber',
        key: 'serialNumber',
        fixed: 'left',
        width: 100,
        
      },
        {
            title: 'Business Name',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 150,
             fixed: 'left',
            ...getColumnSearchProps('companyName'),
            
          },
          // {
          //   title: 'Sales Person',
          //   dataIndex: 'salesPerson',
          //   key: 'salesPerson',
          //   width: 100,
          //   // render:(data)=>{
          //   //   return(
          //   //     <span>{this.truncateString(data,40)}</span>
          //   //   )
          //   // }
          // },
       
        // {
        //     title: 'Support Act Manager',
        //     dataIndex: 'supportAccManager',
        //     key: 'supportAccManager',
        //     width: 100,
        // },
       
        // {
        //   title: 'Charging Model',
        //   dataIndex: 'subscriptionPlans',
        //   key: 'subscriptionPlans',
        //   width: 100,
        //   render:(data)=>{
        //     console.log("data::",data)
        //     if(data.length > 0){
        //         return   (data[0].chargingModel)
        //     }
        //   }
        // },

        {
            title: ' Subscription Start Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            sorter: (a, b) => {
              return a.createdAt -  b.createdAt
            },
            render:(data)=>{ 
              return(
                <div>
               {data != null && 
               moment.unix(data).format("DD-MM-YYYY")
               }
               {data == null && <span>--</span>}
               </div>
              )
            }
          },
          {
            title: 'Renewal Date',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 100,
            sorter: (a, b) => {
              const dateA = new Date(a.endDate);
              const dateB = new Date(b.endDate);
              return dateA - dateB;
            }
          },
          {
            title: 'Renewal Amount',
          
            key: 'charges',
            width: 100,
            render:(data)=>{
              
             return <span>{returnSymbol(parseFloat(data.charges ? data.charges / 100 : 0), data.curr ? data.curr : "INR")}</span>
            }
          },
        ]
        betweenDates && betweenDates.map((ele,i)=>{
            let obj={
              title: ele,
                  // dataIndex: 'createdAt',
                  key: ele,
                  width: 75,
                  align: 'center',
                  render:(data)=>{
                   
                    if(data && data.ordersCount && data.ordersCount.length >0){
                      let orderCount = null
                     data.ordersCount.map((val)=>{
                      if(val.date == ele){
                        orderCount = val.totalOrders
                      }
                     })
                    
                      return{
                        props: {
                          style: { background: orderCount ? "#afdb8b" : "#f4bfc0" }
                        },
                        children: <span>{orderCount ? orderCount : '0'}</span>
                      }
                    }else{
                      return{
                        props: {
                          style: { background:  "#f4bfc0" }
                        },
                        children: <span>{'0'}</span>
                      }
                    }
                  }
            }
            columns.push(obj)
          })
          // let  note={
          //   title: 'Notes',
          //   key: 'notes',
          //   width: 80,
          //   fixed: 'right',
          //   align: 'center',
          //   render:(data)=>{
          //     return(
          //       <Icon type='form' onClick={()=>openNotesModal(data)}/>
          //     )
          //   }
          // }
          // columns.push(note)
    return(
        <App header={"My Dashboard"}>
            
            <Table
                className='sitetable'
                columns={columns}
                    bordered
                    dataSource={renewalRecord}
                    loading={loading}
                    size="middle"
                    rowKey="id"
                    scroll={{ x: '110%' }}
                    pagination={{ pageSize: 100 }}
                />
            <Modal
            title={"Notes"}
            visible={visible}
            onCancel={closeNotesModal}
            footer={null}
            width={1200}>
                    <SiteLogs siteDetails={siteData} />
                </Modal>
        </App>
    )

}
export default withApollo(myDashboard)
