import React, { useState, useEffect } from 'react'
import { Alert, Table } from 'antd';
import { withApollo } from 'react-apollo';
import getInternalDump from '../../queries/getInternalDump';
import moment from 'moment'
import Axios from 'axios';

function performanceDashboard(props) {

  const [dashboardData, setDashboardData] = useState([])
  const [loading, setLoading] = useState(false)

  const flag = (props.userData.userRole == 'ADMIN' || props.userData.userRole == 'CUSTOMER_SUPPORT_LEAD')

  const [MainData, setMainData] = useState(flag ? siteData : [])

  const { siteData, InternalUserData } = props

  const oneMonthagoDate = moment().subtract(6, "month").format('DD-MM-YYYY');
  const currentDate = moment().format('DD-MM-YYYY');

  const columns = [
    {
      title: 'Seq No.',
      dataIndex: 'supportAccManager',
      key: 'seq No',
      width: 100,
      // fixed: 'left',
      render: (text, record, index) => (
        record.supportAccManager !== '' &&  <span>{index + 1}</span>  // Adding 1 to make it 1-based index
      )
    },
    {
      title: 'Account Manager',
      dataIndex: 'supportAccManager',
      key: 'supportAccManager',
      width: 200,
      // fixed: 'left'
    },
    {
      title: 'Total Customer',
      dataIndex: 'total_customer',
      key: 'total_customer',
      // width: 100
    },
    {
      title: 'Using',
      dataIndex: 'used_customer',
      key: 'used_customer',
      // width: 100,
    },
    {
      title: 'Not Using',
      dataIndex: 'unused_customer',
      key: 'unused_customer',
      // width: 100,
    },
    {
      title: 'Total Percentage %',
      dataIndex: 'total_percentage',
      key: 'total_percentage',
      // width: 100,
    }
  ]
  useEffect(() => {

    if (flag) {
      createData(siteData)
    } else {
      getData()
    }

  }, [siteData, InternalUserData])


  function createData(DataSource) {
    // console.log("DataSource::",DataSource)
    let supportAccManager = []
    if (InternalUserData && InternalUserData.length > 0) {
      for (let i = 0; i < InternalUserData.length > 0; i++) {
        if (InternalUserData[i].userRole == 'SUPPORT_ACCOUNT_MANAGER' || InternalUserData[i].userRole == "CUSTOMER_SUPPORT_LEAD") {
          if (InternalUserData[i].enabled) {
            let obj = {
              "supportAccManager": InternalUserData[i].name,
              "total_customer": 0,
              "unused_customer": 0,
              "used_customer": 0,
              "total_percentage": 0
            }
            supportAccManager.push(obj)
          }
        }
      }
    }

    const date = new Date();

    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let year = date.getFullYear();

    // let todayDate = `${day}-${month}-${year}`;
    const todayDate = moment().format('DD-MM-YYYY')
    //   console.log("todayDate::",todayDate)

    const isWithinPastFourDays = (date) => {
      const today = moment();
      const targetDate = moment(date, 'DD-MM-YYYY');
      return targetDate.isBetween(today.clone().subtract(4, 'days').startOf('day'), today.startOf('day'), null, '[]');
    };

    DataSource && DataSource.length > 0 && DataSource.forEach(site => {
      let manager = supportAccManager && supportAccManager.length > 0 && supportAccManager.find(user => user.supportAccManager === site.supportAccManager);
      if (manager) {
        manager.total_customer++;

        // let recentOrders = site.ordersCount.filter(order => order.date !== todayDate);
        // recentOrders = recentOrders.filter(order => isWithinPastFourDays(order.date));
        let recentOrders = site.ordersCount.filter(order => order.date !== todayDate && isWithinPastFourDays(order.date));
        let sliceLength = recentOrders.length > 4 ? -4 : -recentOrders.length;
        recentOrders = recentOrders.slice(sliceLength);

        let isUsing = recentOrders && recentOrders.length > 0 &&
          recentOrders.some(order => order.totalOrders > 0);

        if (isUsing) {
          manager.used_customer++;
        } else {
          manager.unused_customer++;
          // if (manager.supportAccManager == "Akshay Mule") {
          //   console.log("site.ordersCount:::", recentOrders)
          //   console.log("companyName::",site.companyName)
          // }
        }
      }
    });

    supportAccManager.forEach(manager => {
      let totalCust = JSON.parse(JSON.stringify(manager.total_customer))
      let usedCust = JSON.parse(JSON.stringify(manager.used_customer))
      if (manager.total_customer > 0) {

        // manager.used_customer = Math.round(((usedCust/ totalCust ) * 100));
        // manager.unused_customer = Math.round(((manager.unused_customer / totalCust) * 100));
        manager.total_percentage = Math.round(((usedCust / totalCust) * 100));

      }
    });

    //    console.log("supportAccManager:::",supportAccManager)

    supportAccManager && supportAccManager.length > 0 && supportAccManager.sort((a, b) => b.total_percentage - a.total_percentage);

    const totals = {
      supportAccManager: '',
      total_customer: 0,
      unused_customer: 0,
      used_customer: 0,
      total_percentage: 0
  };
  
  supportAccManager.forEach(item => {
      totals.total_customer += item.total_customer;
      totals.unused_customer += item.unused_customer;
      totals.used_customer += item.used_customer;
  });

  totals.total_percentage = Math.round(((totals.used_customer / totals.total_customer) * 100));

    // console.log("supportAccManager::::",supportAccManager)
    // console.log("totals:::",totals)

    supportAccManager.push(totals)

    setDashboardData(supportAccManager)
    setLoading(false)
  }


  const checkDump = () => {
    return new Promise((resolve, reject) => {
      const currentDate = moment().format('YYYY-MM-DD');
      // console.log("currentDate::",currentDate)
      let username = undefined;
      // console.log("userData::",this.props.userData)
      // if (props.userData.userRole != 'ADMIN') {
      //   username = props.userData.username
      // }
      props.client.query({
        query: getInternalDump,
        variables: {
          typeValue: username ? 'onboarding_dashboard::' + username : 'onboarding_dashboard',
          dumpDate: currentDate,
          username: username
        },
        fetchPolicy: 'cache-first'
      }).then(({ data }) => {
        // console.log("getInternalDump::",data)
        resolve(data)
      })
    })
  }



  const getOnboardingData = (param) => {
    // console.log("param :::", param)
    return new Promise((resolve, reject) => {
      Axios({
        method: "GET",
        url: `https://analyser.mysnaptrade.com/onboarding_dashboard`,
        // url: `http://localhost:3202/onboarding_dashboard`,
        params: param,
      })
        .then(function ({ data }) {
          resolve(data)
        })
        .catch(function (error) {
          console.log("In Error :", error);
          resolve([])
        });
    })
  }

  const getTimeStamp = (fromDate, toDate) => {
    //  console.log("getTimeStamp ::",fromDate, toDate)
    fromDate.set({ hour: 0, minute: 1, second: 0, millisecond: 0 });
    toDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

    const startTimestamp = Math.floor(fromDate.valueOf() / 1000);
    const endTimestamp = Math.floor(toDate.valueOf() / 1000);
    // console.log("{startTimestamp, endTimestamp} :::", {startTimestamp, endTimestamp})
    return { startTimestamp, endTimestamp };
  }

  async function getData() {
    setLoading(true)
    const oneMonthagoDate = moment().subtract(6, "month");
    const currentDate = moment();
    const startDate = moment(oneMonthagoDate.format('DD-MM-YYYY'), 'DD-MM-YYYY');
    const endDate = moment(currentDate.format('DD-MM-YYYY'), 'DD-MM-YYYY');

    let dump = await checkDump()
    if (dump && dump.getInternalDump) {
      let sdf = JSON.parse(dump.getInternalDump.dumpData)
      createData(sdf)
      // setMainData(sdf)
      // console.log("sdf:::",sdf)
    } else {
      let username = undefined;
      let salesPerson = undefined;
      let supportAccManager = undefined;
      let respDate = getTimeStamp(oneMonthagoDate, currentDate)
      const obj = {
        fromDate: respDate.startTimestamp,
        toDate: respDate.endTimestamp,
        supportAccManager: supportAccManager,
        salesPerson: salesPerson,
        username: username
      }
      const data = await getOnboardingData(obj)
      let record = data.result ? JSON.parse(data.result) : []
      createData(record)
      // setMainData(record)

      // console.log("record:::",record)
    }



  }

  return (
    <div>
      <div>
        <div class="date-range">
          <h4>Start Date: <span class="date">{oneMonthagoDate}</span> &nbsp; &nbsp; &nbsp; End Date: <span class="date">{currentDate}</span></h4>
        </div>
        {/* <h4> Start Date: {oneMonthagoDate} &nbsp; &nbsp; &nbsp;   End Date: {currentDate}</h4> */}
      </div>
      <Table
        className='sitetable'
        columns={columns}
        bordered
        dataSource={dashboardData}
        loading={loading}
        size="middle"
        rowKey="id"
        scroll={{ x: '110%' }}
        pagination={{ pageSize: 100 }}
      />
    </div>
  )
}

export default withApollo(performanceDashboard);
