import gql from 'graphql-tag'
export default gql`
query getServiceTypesBySiteId($siteId: String!){
    getServiceTypesBySiteId(siteId: $siteId){
      id
      siteId
      slugName
      typeName
      typeDes
      displayName
      typeStatus
      masterServiceType
      displayOrder
      masterServiceType
      image{
        bucket
        region
        key
      }
      createdAt
      updatedAt
      aggregatorMerchantId
      isAggregatorEnabled
      aggregatorSystem
      weraManagedByRecaho
      reservationType
    }
  }`