import gql from 'graphql-tag'

export default gql`
mutation updateSiteDataNormalize(
  	$domain: String!
    $planName: String
    $chargingModel: String
    $status: SERVICE_STATUS
    $masterStatus:  Boolean
    $startDate: String
    $endDate: String
    $charges: Float

){
  updateSiteDataNormalize(input:{ 
    domain: $domain
    planName: $planName
		chargingModel: $chargingModel
    status: $status
		masterStatus: $masterStatus
    startDate: $startDate
    endDate: $endDate
    charges: $charges
  }) {
    domain
    siteId
    planName
    chargingModel
    status
    masterStatus
    startDate
    endDate
    charges
  }
}`
//   {
//       "domain": "testsite.recaho.com",
//       "siteId": "e6e15d4e-8a5a-44c9-a737-3f2e6d14796b",
//       "enabled": true,
//       "siteServices": [
//         {
//           "serviceName": "site",
//           "chargingModel": "MONTHLY",
//           "charges": 50,
//           "status": true,
//           "masterStatus": true,
//           "startedAt": 1630662794,
//           "updatedAt": 1630662794
//         },
//         {
//           "serviceName": "WHATSAPP",
//           "chargingModel": "MONTHLY",
//           "charges": 10,
//           "status": true,
//           "masterStatus": true,
//           "startedAt": 1630662794,
//           "updatedAt": 1630662794
//         }
//       ]
//     }