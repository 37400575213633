import gql from 'graphql-tag'
export default gql`
mutation updateServiceType(
    $siteId: String!
    $slugName: String!
	$typeName: String!
	$typeDes: String
	$image: S3ObjectInput
	$typeStatus: Boolean
	$displayOrder: Int
    $aggregatorMerchantId: String
    $isAggregatorEnabled: Boolean
    $aggregatorSystem: String
    $reservationType: String
    $displayName: String
    $weraManagedByRecaho: Boolean
 ){
    updateServiceType(input: {
        siteId: $siteId
        slugName:  $slugName
        typeName: $typeName
        typeDes: $typeDes
        image: $image
        typeStatus: $typeStatus
        displayOrder: $displayOrder
        aggregatorMerchantId: $aggregatorMerchantId
        isAggregatorEnabled: $isAggregatorEnabled
        aggregatorSystem: $aggregatorSystem
        reservationType: $reservationType
        displayName: $displayName
        weraManagedByRecaho: $weraManagedByRecaho
    }){
        siteId
        slugName
        displayName
        typeName
        typeDes
        typeStatus
        displayOrder
        aggregatorMerchantId
        isAggregatorEnabled
        aggregatorSystem
        reservationType
        weraManagedByRecaho
    }
   
  }
  
`  