import React, { Component } from 'react';
import moment from 'moment'
import { Button,  Table, Col, DatePicker,  Form, Input, Popconfirm, Row, Select, message,Tabs, Spin,Modal } from 'antd'
import { withApollo } from 'react-apollo';


import getSiteConfig from '../../queries/getSiteConfig'
import AddExtraService from './addExtraServiceToSite'
import SpAddToSite from './SpAddtoSite'
import getSiteConfigServices from '../../queries/getSiteConfigServices'
import getSiteTransactionsByCreatedAt from '../../queries/getSiteTransactionsByCreatedAt'
import updateSiteConfig from '../../mutation/updateSiteConfig'
import updateSiteDataNormalize from '../../mutation/updateSiteDataNormalize'

import updateSubscriptionPlan from '../../mutation/updateSubscriptionPlan'
import getSubscriptionPlansBySiteId from '../../queries/getSubscriptionPlansBySiteId'
import getSiteWalletById from '../../queries/getSiteWalletById'
import SiteRecharge from './siteRecharge'
import UserOperation  from '../../utils/userOperations'
import SiteActionEvent from '../../funComponent/SiteActionEvent'


// import createSiteConfig from '../../mutation/createSiteConfig'


const { Option } = Select;
const { TextArea } = Input;

const FormItem = Form.Item

const { TabPane } = Tabs;

let dispVal = []

class PaymentDetails extends Component {
    constructor(props){
        super(props)
        this.state={
            loading:false,
            loadingTransaction:false,
            allService: [],
            specTypeDisplay: [],
            specStatus: [],
            transaction: [],
            sitePlans: [],
            visibleM: false,
            updatePlan: false,
            reason: false,
            selectedCategory: 'Billing',
            selectedReason: undefined,
            saveLoading: false

        }
    }
    componentDidMount(){
        this.enterLoading()
        this.callPromise()
        this.getSitePlan()
        this.getRecharge()
        
    }
    callback=(key)=> {
        if( key === "3"){
            this.getTransactions()
        }
        // if( key === "1"){
        //     this.getSiteConfig()
        // }
      }
    async callPromise (){
        const [ getSiteConfig,getServiceCharges] = await Promise.all([
            this.getSiteConfig(),
            this.getServiceCharges()
          ])
        //   console.log("response ::",getSiteConfig,getServiceCharges)
             let ms = []
                let recData = []
                let arr = getSiteConfig != null ? getSiteConfig : []
                arr.siteServices.map((vel)=>{
                    ms.push(vel.serviceName)
                    recData.push(vel)
                })
          this.setState({
            serviceData: getSiteConfig,
            allService: getServiceCharges,
            ms: ms,
            recData:recData,
            loading:false
          })
    }
    enterLoading=()=>{
        this.setState({
            loading:true
        })
    }
    getSiteConfig = () => {
        return new Promise((resolve,reject)=>{
        this.props.client.query({
            query: getSiteConfig,
            variables: {
                domain: this.props.siteData.fqdn
            },
            fetchPolicy: 'network-only'
        })
            .then(({ data }) => {
             
                resolve( data.getSiteConfig)
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
        })
    }
    getServiceCharges = () => {
        return new Promise((resolve,reject)=>{

        this.props.client.query({
            query: getSiteConfigServices,
            variables: {
                domain: "recaho"
            },
            fetchPolicy: 'network-only'
        })
            .then(({ data }) => {
                // console.log('dataa', data)
                resolve(data.getSiteConfigServices)
           
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
        })
    }
    getSitePlan=()=>{
        this.props.client.query({
          query: getSubscriptionPlansBySiteId,
          variables: {
              siteId: this.props.siteData.siteId
          },
          fetchPolicy: 'no-cache'
        })
          .then(({ data }) => {
            // console.log('dataa', data)
            let ms= this.state.selectedMS
            this.setState({
              sitePlans:data.getSubscriptionPlansBySiteId,
            //   miCheck:data.getSubscriptionPlansBySiteId.length > 0 ? true : false, 
              // selectedMS: data.getSubscriptionPlansBySiteId.length > 0 ? ms : [],
              loading: false
            })
          })
      }
      getRecharge=()=>{
        //   console.log("In getRecharge")
        this.props.client.query({
          query: getSiteWalletById,
          variables: {
              siteId: this.props.siteData.siteId
          },
          fetchPolicy: 'no-cache'
        })
          .then(({ data }) => {
            // console.log('dataa', data)
            this.setState({
              currentB: data.getSiteWalletById,
            })
          })
      }
    getTransactions=()=>{
        //   console.log("In Transactions")
        this.setState({
            loadingTransaction: true
        })
        this.props.client.query({
          query: getSiteTransactionsByCreatedAt,
          variables: {
              siteId: this.props.siteData.siteId,
              type: "CREDIT",
              limits: 50,
              skips: 0
          },
          fetchPolicy: 'no-cache'
        })
          .then(({ data }) => {
            // console.log('dataa Transactions', data)
            let trData = data.getSiteTransactionsBySite
            let sorted = trData.sort(function(a, b) {
                return b.createdAt - a.createdAt;
            });
            this.setState({
            transaction: sorted,
              loadingTransaction: false
            })
          })
      }

    handleTypeChange = (param, type) => {
        // console.log("handleTypeChange",param, type)
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');

        if (!('value' in this.props)) {
            this.setState({ type });
            dispVal[param] = type
            this.setState({
                specTypeDisplay: dispVal,
                specStatus: dispVal,
                updatePlan: true
            });
        }
        this.triggerChange({ type });
    }
    masterStatusOnChange=(param, type)=>{
        if(type == false){
            this.setState({
                reason: true
            })
    }
    }
    onCategoryChange=(value)=>{
        this.setState({
            selectedCategory: value
        })
    }
    OnselectedReason=(value)=>{
        this.setState({
            selectedReason: value,
        })
    }
    textAreaChnages=(e)=>{
        this.setState({
            notes: e.target.value
        })
    }
    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(Object.assign({}, this.state, changedValue));
        }
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(!err){
                // console.log("Values",values)
                this.setState({
                    saveLoading: true
                })
                if(this.state.notes && this.state.selectedCategory && (this.state.reason ? this.state.selectedReason : true)){
                this.updateChargeModal(values.charges)
                this.createLogs()
                }else{
                    if(this.state.notes == undefined){
                        message.error("Please Enter Notes")
                    }
                    if(this.state.selectedCategory ==  undefined){
                        message.error("Please Select Category")
                    }
                    if(this.state.reason){
                        message.error("Please Select Reason")
                    }
                    this.setState({
                        saveLoading: false
                    })
                }
            }
        })
    }

    updateSiteDataNormalizeFun=(domain,record)=>{
        return new Promise((resolve,reject)=>{

            try {
          
          const { 
            // planName,
            chargingModel,
            status,
            masterStatus,
            startDate,
            endDate,
            charges,
          } = record;
  
          this.props.client.mutate({
            mutation: updateSiteDataNormalize,
            variables: {
                domain,
                // planName,
                chargingModel,
                status,
                masterStatus,
                startDate,
                endDate,
                charges,
            }
        }).then(({ data }) => {
            console.log("updateSiteDataNormalizeFun suc ::", data)
        })
        .catch(err=>{
          console.log("updateSiteDataNormalizeFun Err ::", err)
          console.log("-- ::")

  
        })
              
    } catch (error) {
          console.log("updateSiteDataNormalizeFun catch ::", error)
                
    }
        })
      }
  
    updateChargeModal = (data) => {
        // console.log("In updateChargeModal ::", data,this.state.sitePlans)
        let chargeData = data && data.length > 0 ? data : []
        let newCharge = []
        let sitePlans = this.state.sitePlans
        let updatedPlan = []
        chargeData.map((val) => {
            let obj = {
                charges: parseInt(val.charges * 100),
                chargingModel: val.chargingModel,
                masterStatus: val.masterStatus,
                serviceName: val.serviceName,
                startedAt: val.startedAt,
                status: val.status,
                startDate: val.startDate ? moment(val.startDate).format("YYYY-MM-DD") : undefined,
                endDate: val.startEnd ? moment(val.startEnd).format("YYYY-MM-DD") : undefined,
                // notes: val.notes,
                // endDate: (val.chargingModel == "PAY_PER_USE" && (val.status == 'TRIAL' || val.status == 'FREE' || val.status == 'INACTIVE')) ? moment(val.startEnd).format("YYYY-MM-DD") : (val.chargingModel == "MONTHLY" || val.chargingModel == "YEARLY") ? moment(val.startDate).add(val.chargingModel == "MONTHLY" ? 1 : 12, "M").format("YYYY-MM-DD") : undefined,
                updatedAt: val.updatedAt
            }
            newCharge.push(obj)

            let index = sitePlans.findIndex((x)=>(x.masterService == val.serviceName && (x.chargingModel != val.chargingModel || x.amount != val.charges* 100)))
            if(index != -1){
                let plan = sitePlans[index]
                let obj={...plan,amount: val.charges* 100,chargingModel: val.chargingModel}
                // console.log("obj ::",obj)
                updatedPlan.push(obj)
                
            }
            
        })
        // console.log("new Data ::", newCharge)
        let arrayFilter = newCharge.filter(val => val.chargingModel != undefined)
        // console.log("new filter ::",arrayFilter,updatedPlan)
        // if(updatedPlan && updatedPlan.length >0){
        //     this.updateSitePlan(updatedPlan)
        // }
        // return
        if(arrayFilter && arrayFilter.length > 0){
        this.props.client.mutate({
            mutation:  updateSiteConfig,
            variables: {
                domain: this.props.siteData.fqdn,
                siteId: this.props.siteData.siteId,
                siteServices: arrayFilter,
                enabled: this.props.siteData.enabled
            }
        }).then(({ data }) => {
            console.log("Resp SiteConfig ::", data)
            message.success("Recurring Services Successfully Updated")

            let siteConfigFilter = arrayFilter.filter(obj => obj.serviceName == "SITE")

            // console.log("Site Data :", siteConfigFilter)

            if(siteConfigFilter.length > 0 ) {
                this.updateSiteDataNormalizeFun(this.props.siteData.fqdn, siteConfigFilter[0]);
            }

            if(updatedPlan && updatedPlan.length >0){
                this.updateSitePlan(updatedPlan)
                this.setState({
                    saveLoading: false
                })
            }

        })
        }else{
            console.log("Service error")
            return
        }
    }
    updateSitePlan=(plan)=>{
        for(let i=0;i<plan.length;i++){
            // console.log("Resp updateSitePlan ::", plan[i])
            this.props.client.mutate({
                mutation:  updateSubscriptionPlan,
                variables: {
                    siteId: plan[i].siteId,
                    planId: plan[i].planId,
                    // planName: plan[i].planName,
                    status: plan[i].status,
                    amount: plan[i].amount,
                    chargingModel: plan[i].chargingModel,
                    // masterService: plan[i].masterService,
                    // platform: plan[i].platform,
                    // siteType: plan[i].siteType,
                    // description: plan[i].description,
                    services: plan[i].services,
                    // curr: plan[i].curr,
                    // priceList: plan[i].priceList
                }
            }).then(({ data }) => {
                console.log("Resp updateSitePlan ::", data)
            })
        }
    }
    createLogs=async()=>{
        let obj = {
            siteId: this.props.siteData.siteId,
            eventName: this.state.selectedCategory,
            eventType: 'MANUAL',
            reason: this.state.selectedReason,
            note: this.state.notes
        }
        let resp = await SiteActionEvent(this.props,obj)
        if(resp){
            this.setState({
              notes: undefined
            })
          }
    }
    handleCancel=()=>{
        this.setState({
          visibleM: false
      },()=>{
        this.getRecharge()
      })
      }

    modalChange=()=>{
        let userPermissions = UserOperation('site','wallet_recharge',this.props.userData)
        if(userPermissions){
        this.setState({
          visibleM: true,
        })
        }else{
            message.error(`Dear User, You don't have access to this page.`)
          }
      }
    columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '10%',
            render:(data)=>{
              var dateString = moment.unix(data).format("DD/MM/YYYY HH:mm");
             
              return(
                  <span>{dateString}</span>
              )
          }
        },{
          title: 'Service',
          dataIndex: 'service',
          key: 'service',
          width: '10%',
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transactionType',
            key: 'transactionType',
            width: '10%',
          },
          {
            title: 'Transaction Amount',
            // dataIndex: 'amount',
            key: 'amount',
            width: '10%',
            render:(data)=>{
              let amoutString = (data.amount/100).toLocaleString(`en-${!(data.currency) && data.currency == 'INR' ? 'IN' : data.currency == 'USD' ? 'US' : 'IN'}`, { style: 'currency', currency: data.currency ? data.currency : "INR"  })
             
              return(
                  <span>{amoutString}</span>
              )
          }
          },
          {
            title: 'Balance',
            width: '10%',
            key: 'Balance',
            render:(data)=>{
              let balanceString = (data.balance/100).toLocaleString(`en-${!(data.currency) && data.currency == 'INR' ? 'IN' : data.currency == 'USD' ? 'US' : 'IN'}`, { style: 'currency', currency: data.currency ? data.currency : "INR" })
             
              return(
                  <span>{balanceString}</span>
              )
          }
          },{
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            width: '20%',
        },
        // {
        //     title: 'Invoice',
        //     width: '10%',
        //     key: 'Invoice',
        //     render:(record)=>{
        //         return(
        //             <>
        //             {record.attachedInvoice &&  Object.keys(record.attachedInvoice).length != 0 && record.attachedInvoice.invoicePdf &&  Object.keys(record.attachedInvoice.invoicePdf).length != 0  ?
        //              <>
        //              <a href={`https://${record.attachedInvoice.invoicePdf.bucket}.s3.amazonaws.com/${record.attachedInvoice.invoicePdf.key}`}  target="_blank" >
        //                 <Tooltip placement="top" title={"Download Invoice"}>
        //                     <Icon type="download" style={{ fontSize: '15px',marginRight: '5px'}}/>
        //                 </Tooltip>
        //              </a> 
        //              <Tooltip placement="top" title={"Send Invoice"}>
        //              <Icon type='mail' onClick={()=>this.sendWhatsAppFunction(record.attachedInvoice.invoicePdf,)} style={{ fontSize: '15px',marginRight: '5px'}}/>
        //              </Tooltip>
        //              <Tooltip placement="top" title={"Regenerate Invoice"}>
        //              <Icon type='redo' onClick={()=>this.modalInvoice(record)} style={{ fontSize: '15px',marginRight: '5px'}}/>
        //              </Tooltip>
        //             </> :
        //              <>
        //             {record.transactionId == null ?
        //                 <Icon type='thunderbolt' onClick={()=>this.generateTransaction(record)} />
        //             : 
        //             <Icon type='form' onClick={()=>this.modalInvoice(record)}/>
        //             }
        //             </>
        //             }
        //           </>  
        //         )
        //     }
        //   }
    ];
    
    render() {
        // let siteData = this.props.siteData
        // console.log("partnerType",siteData.siteOptions)
       const { getFieldDecorator, getFieldValue } = this.props.form;
       let bb=  this.state.currentB && this.state.currentB.balance ? this.state.currentB.balance/100 : "0.00"
       let balane = bb.toLocaleString(`en-${
           !(this.props.siteData.curr) && this.props.siteData.curr == 'INR' ? 'IN' : 
           this.props.siteData.curr == 'USD' ? 
           'US' : 'IN'}`, { style: 'currency', currency: this.props.siteData.curr ? this.props.siteData.curr : "INR" })


       let date = new Date()
       let epo = Math.floor(date / 1000)
       // console.log("epo", epo)
       const formItemLayout = {
           labelCol: {
               xs: { span: 24 },
               sm: { span: 24 },
           },
           wrapperCol: {
               xs: { span: 24 },
               sm: { span: 20 },
           },
       };
       const formItemLayoutWithOutLabel = {
           wrapperCol: {
               xs: { span: 24 },
               sm: { span: 20 },
           },
       };
       let chargesEnum = [{
           name: "PAY PER USE",
           typeValue: "PAY_PER_USE",
       }, {
           name: "MONTHLY",
           typeValue: "MONTHLY",
       }, {
           name: "YEARLY",
           typeValue: "YEARLY",
       }]
       let chargeData = this.state.serviceData != undefined ? this.state.serviceData.siteServices : []
       getFieldDecorator('keys', { initialValue: chargeData });
       const keys = getFieldValue('keys');
       const formItems = keys.map((k, index) => (
           <Row key={k} gutter={24}>
               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3}} lg={{ span: 3}}>
                   <Form.Item
                       className="customF"
                       {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                       label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500, }}>Service</span> : ''}
                       required={false}
                       key={index + "service"}
                   >
                       {getFieldDecorator(`charges[${index}]serviceName`, {
                           validateTrigger: ['onChange', 'onBlur'],
                           initialValue: k.serviceName,
                           rules: [
                               {
                                   required: true,
                                   whitespace: true,
                                   message: "Please input select Service or delete this field.",
                               },
                           ],
                       })(<Select disabled style={{ width: '100%' }} onChange={(e) => this.handleTypeChange(index, e)} placeholder="Select Service" >
                           {this.state.allService.map((val, i) => {
                               return (
                                   <Option value={val.serviceName}>{val.serviceName}</Option>
                               )
                           })}
                       </Select>)}
                   </Form.Item>
               </Col>
               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                   <FormItem
                       className="customF"
                       {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                       label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Charge Model</span> : ''}
                       required={false}
                       key={index + "Charge Model"}
                   >
                       {getFieldDecorator(`charges[${index}]chargingModel`, {
                           validateTrigger: ['onChange', 'onBlur'],
                           initialValue: k.chargingModel,
                           rules: [
                               {
                                   required: true,
                                   whitespace: true,
                                   message: "Please input select Service or delete this field.",
                               },
                           ],
                       })(<Select style={{ width: '100%' }} onChange={(e) => this.handleTypeChange(index, e)} placeholder="Select Service" >
                           {chargesEnum.map((val, i) => {
                               return (
                                   <Option value={val.typeValue}>{val.name}</Option>
                               )
                           })}
                       </Select>)}
                   </FormItem>
               </Col>
               {/* {this.state.specTypeDisplay && (this.state.specTypeDisplay[index] == 'MONTHLY' || this.state.specTypeDisplay[index] == 'YEARLY' || (this.state.specTypeDisplay[index] == 'PAY_PER_USE') || this.state.specStatus[index] == 'ACTIVE' || this.state.specStatus[index] == 'TRIAL' || this.state.specStatus[index] == 'FREE') && */}
                   <>
                       <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                           <FormItem
                               className="customF"
                               {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                               label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Start Date</span> : ''}
                               required={false}
                               key={index + "Start Date"}
                           >
                               {getFieldDecorator(`charges[${index}]startDate`, {
                                   initialValue: k.startDate ? moment(k.startDate) : null,
                                   rules: [
                                       {
                                           required: true,
                                           message: "Please input select Service or delete this field.",
                                       },
                                   ],
                               })(<DatePicker></DatePicker>)}
                           </FormItem>
                       </Col>
                   </>
               {/* } */}
               {/* {this.state.specTypeDisplay && (this.state.specTypeDisplay[index] == 'MONTHLY' || this.state.specTypeDisplay[index] == 'YEARLY' || this.state.specStatus[index] == 'ACTIVE' || this.state.specStatus[index] == 'TRIAL' || this.state.specStatus[index] == 'FREE') && */}
                   <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                       <FormItem
                           className="customF"
                           {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                           label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>End Date</span> : ''}
                           required={false}
                           key={index + "End Date"}
                       >
                           {getFieldDecorator(`charges[${index}]startEnd`, {
                              initialValue: k.endDate ? moment(k.endDate) : null,
                               rules: [
                                   {
                                       required: this.state.wallet ? true : false,
                                       message: "Please input select Service or delete this field.",
                                   },
                               ],
                           })(<DatePicker></DatePicker>)}
                       </FormItem>
                   </Col>
               {/* } */}
               {/* {k.startDate && this.state.specTypeDisplay.length == 0  && (
                   <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                       <FormItem
                           className="customF"
                           {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                           label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Start Date</span> : ''}
                           required={false}
                           key={index + "Start Date"}
                       >
                           {getFieldDecorator(`charges[${index}]startDate`, {
                               initialValue: k.startDate ? moment(k.startDate) : null,
                               rules: [
                                   {
                                       required: true,
                                       message: "Please input select Service or delete this field.",
                                   },
                               ],
                           })(<DatePicker></DatePicker>)}
                       </FormItem>
                   </Col>
               )

               } */}
               {/* {k.endDate && this.state.specTypeDisplay.length == 0  && (
                   <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                       <FormItem
                           className="customF"
                           {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                           label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>End Date</span> : ''}
                           required={false}
                           key={index + "End"}
                       >
                           {getFieldDecorator(`charges[${index}]startEnd`, {
                               initialValue: k.endDate ? moment(k.endDate) : null,
                               rules: [
                                   {
                                       required: true,
                                       message: "Please input select Service or delete this field.",
                                   },
                               ],
                           })(<DatePicker ></DatePicker>)}
                       </FormItem>
                   </Col>
               )
               } */}
               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                   <FormItem
                       className="customF"
                       {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                       label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Rate</span> : ''}
                       required={false}
                       key={index + "Rate"}
                   >
                       {getFieldDecorator(`charges[${index}]charges`, {
                           validateTrigger: ['onChange', 'onBlur'],
                           initialValue: k.charges ? k.charges / 100 : null,
                           rules: [
                               {
                                   required: true,
                                   message: "Please input select Service or delete this field.",
                               },
                           ],
                       })(<Input placeholder="Rate" type="Number" ></Input>)}
                   </FormItem>
               </Col>
               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                   <FormItem
                       className="customF"
                       {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                       label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Billing Status</span> : ''}
                       required={false}
                       key={index + "Billing Status"}
                   >
                       {getFieldDecorator(`charges[${index}]status`, {
                           initialValue: k.status ? k.status : true,
                           rules: [
                               {
                                   required: false,
                                   message: "Please input select  status or delete this field.",
                               },
                           ],
                       })(<Select style={{ width: '90px' }} onChange={(e) => this.handleTypeChange(index, e)}>
                           <Option value={"ACTIVE"}>Active</Option>
                           {/* <Option value={"INACTIVE"}>Inactive</Option> */}
                           <Option value={"TRIAL"}>Trial</Option>
                           <Option value={"FREE"}>Free</Option>
                       </Select>)}
                   </FormItem>
               </Col>
               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                   <FormItem
                       className="customF"
                       {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                       label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Master Status</span> : ''}
                       required={false}
                       key={index + "Master Status"}
                   >
                       {getFieldDecorator(`charges[${index}]masterStatus`, {
                           initialValue: k.masterStatus,
                           rules: [
                               {
                                   required: true,
                                   message: "Please input select  status or delete this field.",
                               },
                           ],
                       })(<Select style={{ width: '90px' }} onChange={(e)=>this.masterStatusOnChange(index,e)}>
                           <Option value={true}>On</Option >
                           <Option value={false}>Off</Option >
                       </Select>)}
                   </FormItem>
               </Col>
               {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }}>
                   <FormItem
                       className="customF"
                       {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                       label={index === 0 ? <span style={{ fontSize: '15px', fontWeight: 500 }}>Notes</span> : ''}
                       required={false}
                       key={index + "Notes"}
                   >
                       {getFieldDecorator(`charges[${index}]notes`, {
                           initialValue: k.notes,
                           rules: [
                               {
                                   required: true,
                                   message: "Please input select  status or delete this field.",
                               },
                           ],
                       })(<Input />)}
                   </FormItem>
               </Col> */}
               <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0 }} key={index + "Master"}>
                   {getFieldDecorator(`charges[${index}]startedAt`, {
                       initialValue: k.startedAt ? k.startedAt : epo,
                       rules: [
                           {
                               required: false,
                               message: "Please input select  status or delete this field.",
                           },
                       ],
                   })}

                   {getFieldDecorator(`charges[${index}]updatedAt`, {
                       initialValue: epo,
                       rules: [
                           {
                               required: false,
                               message: "Please input select  status or delete this field.",
                           },
                       ],
                   })}
               </Col>
           </Row>

       ));
        
        return (
           <>
           {this.state.loading ? 
           <Spin></Spin>:
           <>
              <p style={{ textAlign: 'end' }}>
                                <Button type="dashed" style={{  marginRight: "20px", margintop: '10px', marginBottom: '20px' }} >
                                   <span>Balance: <b>{balane}</b></span>
                                </Button>
                                <Button type="primary" style={{ background: "#389e0d", color: "#fff", marginRight: "20px", margintop: '10px', marginBottom: '20px' }} onClick={()=>this.modalChange()} >
                                    Recharge
                                </Button>
                        </p>
            <Tabs onChange={this.callback} type="card">
                            <TabPane tab="Recurring Services" key="1">
                                <Row gutter={24} style={{marginLeft: '10px',marginRight: '10px'}}>
                                {formItems}
                                </Row>
                                <div className='planCardSection' >
                                <Row gutter={24}  style={{marginLeft: '10px',marginRight: '10px'}}>
                                <p class='planCardSectionText' style={{fontWeight: 700}}>Notes</p>
                                <Select
                                    placeholder={"Select Category"}
                                    onChange={this.onCategoryChange}
                                    value={this.state.selectedCategory}
                                    style={{ width: '100%',marginBottom: '10px' }}
                                    showSearch
                                    >
                                        <Select.Option key="Billing" value="Billing">Billing</Select.Option>
                                        <Select.Option key="Complaint" value="Complaint">Complaint</Select.Option>
                                        <Select.Option key="Customer Requirement" value="Customer Requirement">Customer Requirement</Select.Option>
                                        <Select.Option key="Onboarding" value="Onboarding">Onboarding</Select.Option>
                                        <Select.Option key="Setup" value="Setup">Setup</Select.Option>
                                        <Select.Option key="Support" value="Support">Support</Select.Option>
                                        <Select.Option key="Renewal Notes" value="Renewal Notes">Renewal Notes</Select.Option>

                                </Select>
                                {this.state.reason ? 
                                 <Select
                                 placeholder={"Select Reason"}
                                 onChange={this.OnselectedReason}
                                 value={this.state.selectedReason}
                                 style={{ width: '100%',marginBottom: '10px' }}
                                 allowClear
                                 showSearch
                                 >
                                     <Select.Option key="Billing Overdue" value="Billing Overdue">Billing Overdue</Select.Option>
                                     <Select.Option key="Lost - Sales Issue" value="Lost - Sales Issue">Lost - Sales Issue</Select.Option>
                                     <Select.Option key="Lost - Service Issue" value="Lost - Service Issue">Lost - Service Issue</Select.Option>
                                     <Select.Option key="Lost - Application Issue" value="Lost - Application Issue">Lost - Application Issue</Select.Option>
                                     <Select.Option key="Store Closed" value="Store Closed">Store Closed</Select.Option>

                             </Select>
                                 : ''}
                                <TextArea value={this.state.notes} placeholder='Add a note' onChange={this.textAreaChnages} rows={4} />
                                </Row>
                                </div>
                            <Button type="primary" onClick={(e)=>this.handleSubmit(e)} loading={this.state.loading} style={{ float: "right", marginBottom: 16,marginTop: 10, cursor: "pointer", color: "#fff" }}>
                                Save
                            </Button>
                            </TabPane>
                            <TabPane tab="One Time Services" key="2">
                            <AddExtraService siteData={this.props.siteData} getRecharge={this.getRecharge} />

                                </TabPane>
                            <TabPane tab="Credit Transactions" key="3">
                            <Table 
                                columns={this.columns} 
                                dataSource={this.state.transaction}
                                loading={this.state.loadingTransaction}
                                />
                            </TabPane>
                            <TabPane tab="Manage Subscription" key="4">
                            <SpAddToSite siteResp={this.props.siteData} isEdit={true} ms={this.state.masterSer} recData={this.state.recData}/>
                            </TabPane>
                            </Tabs>
            </>
            }
              <Modal
                    title="Wallet Recharge"
                    visible={this.state.visibleM}
               
                    onCancel={this.handleCancel}
                    footer={null}
                  >
                   <SiteRecharge siteData={this.props.siteData} handleCancel={this.handleCancel}/>
                </Modal>
           </>
        );
    }
}
const EditPaymentDetails = Form.create()(PaymentDetails);

export default withApollo(EditPaymentDetails);