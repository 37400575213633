import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { withApollo } from 'react-apollo';
import { Table, Row, Col, Select, Input, Button, Icon, Modal, DatePicker } from 'antd';
import getInternalDump from '../../queries/getInternalDump'
import getRenewalCount from '../../queries/getRenewalCount'
import getSubscriptionPlansBySiteId from '../../queries/getSubscriptionPlansBySiteId'
import getInternalUsers from '../../queries/getInternalUsers'
import SiteLogs from '../deploymentDashboard/siteLogs'
import { returnSymbol } from '../../utils/returnSymbol'
import App from '../../App'
import axios from 'axios';

const { MonthPicker } = DatePicker;
function RenewalDashboard(props) {
  const [renewalRecord, setrenewalRecord] = useState([])
  const [allrenewalRecord, setallrenewalRecord] = useState([])
  const [betweenDates, setbetweenDates] = useState([])
  const [InternalUserData, setInternalUserData] = useState([])
  const [allPlan, setallPlan] = useState([])
  const [loading, setLoading] = useState(false);
  const [filterloading, setfilterloading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [siteData, setsiteData] = useState(new Object());
  const [supportAccM, setsupportAccM] = useState(undefined);
  const [salesPerson, setsalesPerson] = useState(undefined);
  const [partnerType, setpartnerType] = useState(undefined);
  const [partnerName, setpartnerName] = useState(undefined);
  const [companyName, setcompanyName] = useState(undefined);
  const [ planeNameSearch, setPlaneNameSearch] = useState(undefined);
  const [month, setmonth] = useState(undefined);
  const [monthValue, setMonthValue] = useState(undefined)
  useEffect(() => {
    setLoading(true)
    callPromise()
  }, [props])

  async function callPromise() {

    const currentDate = moment().subtract(1, "days")
    const pastDate = moment().subtract(10, "days");
    const toDate = moment().add(30, "days");

    let respDate = getTimeStamp(pastDate, moment().subtract(15, "days"))

    getMasterPlan()
    // console.log("respDate",respDate)
    let variables = {
      "fromDate": respDate.endTimestamp,
      "toDate": toDate.format('YYYY-MM-DD'),
      "pastDate": respDate.startTimestamp,
      // "currentDate": currentDate.format('YYYY-MM-DD')
    }
    const startDate = moment(pastDate.format('DD-MM-YYYY'), 'DD-MM-YYYY');
    const endDate = moment(currentDate.format('DD-MM-YYYY'), 'DD-MM-YYYY');

    // Create an array to store the between dates
    let betweenDates = [];

    // Clone the start date for iteration
    let currentDate1 = startDate.clone();

    // Iterate through the dates and add them to the array
    while (currentDate1.isSameOrBefore(endDate, 'day')) {
      betweenDates.push(currentDate1.format('DD-MM-YYYY'));
      currentDate1.add(1, 'day');
    }
    betweenDates.reverse()
    // console.log("betweenDates",betweenDates)
    let users = await getUsers()
    setbetweenDates(betweenDates)
    setInternalUserData(users)
    // let dump = await checkDump()
    let dump = false
    if (dump && dump.getInternalDump && dump.getInternalDump.dumpData && dump.getInternalDump.dumpData != '[]') {
      // if(false){
      let sdf = JSON.parse(dump.getInternalDump.dumpData)
      let filterData=[], TotalSubscriptionAmonut = 0;
      for(let i = 0;i<sdf.length;i++){
        let obj = {
          ...sdf[i],
          seqNo: i+1
        }
        filterData.push(obj)
        let amount = sdf[i].subscriptionPlans && sdf[i].subscriptionPlans.length > 0 ?  sdf[i].subscriptionPlans[0].amount : 0;
        TotalSubscriptionAmonut = TotalSubscriptionAmonut + amount
      }
      let obj = {
        subscriptionPlans : [{  
            "amount": TotalSubscriptionAmonut
        }]
      }

      let recordWithTotalSubAmt = JSON.parse(JSON.stringify(filterData)) 
      if(recordWithTotalSubAmt && recordWithTotalSubAmt.length > 0){
      recordWithTotalSubAmt.push(obj)
      }
      // sdf.push(obj)
      // console.log("sdf dump::",sdf)
      setrenewalRecord(recordWithTotalSubAmt)
      setallrenewalRecord(sdf)
      setLoading(false)
    } else {
      let record = await getRenewalData(variables)
      // console.log("record ::", record)
      if (record.result) {
      let json = JSON.parse(record.result)
      // console.log("json ::", json)
      let filterData=[], TotalSubscriptionAmonut = 0;
      for(let i = 0;i<json.length;i++){
        let obj = {
          ...json[i],
          seqNo: i+1
        }
        filterData.push(obj)
        let amount = json[i].subscriptionPlans && json[i].subscriptionPlans.length > 0 ?  json[i].subscriptionPlans[0].amount : 0;
        TotalSubscriptionAmonut = TotalSubscriptionAmonut + amount
      }
     
      let obj = {
        subscriptionPlans : [{  
            "amount": TotalSubscriptionAmonut
        }]
      }
   
       let recordWithTotalSubAmt = JSON.parse(JSON.stringify(filterData)) 
       if(recordWithTotalSubAmt && recordWithTotalSubAmt.length > 0){
        recordWithTotalSubAmt.push(obj)
        }
      // json.push(obj)
        setrenewalRecord(recordWithTotalSubAmt)
        setallrenewalRecord(filterData)
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
  }

  const checkDump = () => {
    return new Promise((resolve, reject) => {
      const currentDate = moment().format('YYYY-MM-DD');
      // console.log("currentDate::",currentDate)
      props.client.query({
        query: getInternalDump,
        variables: {
          typeValue: 'renewal_dashboard',
          dumpDate: currentDate,
        },
        fetchPolicy: 'cache-first'
      }).then(({ data }) => {
        // console.log("getInternalDump::",data)
        resolve(data)
      })
    })
  }

  const checkDumpForPreviousMonths = (date) => {
    return new Promise((resolve, reject) => {
      // const currentDate = moment().format('YYYY-MM-DD');
      // console.log("currentDate::",currentDate)
      props.client.query({
        query: getInternalDump,
        variables: {
          typeValue: 'renewal_dashboard',
          dumpDate: date,
        },
        fetchPolicy: 'network-only',
      }).then(({ data }) => {
        // console.log("getInternalDump::",data)
        resolve(data)
      })
    })
  }

  const getRenewalData = (param) => {
    // console.log("param :::", param)
    return new Promise((resolve, reject) => {
      axios({
        method: "GET",
        url: `https://analyser.mysnaptrade.com/renewal_dashboard`,
        // url: `http://localhost:3202/renewal_dashboard`,
        params: param,
      })
        .then(function ({ data }) {
          // console.log("data",data)
          resolve(data)
        })
        .catch(function (error) {
          console.log("In Error :", error);
          resolve([])
        });
    })
  }

  const getRenewal = (variables) => {
    // console.log("variables",variables)
    return new Promise((resolve, reject) => {
      props.client.query({
        query: getRenewalCount,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then(({ data }) => {
        console.log("getRenewalCount::", data)
        resolve(data)
      })
    })

  }

  const getMasterPlan = () => {
    props.client.query({
      query: getSubscriptionPlansBySiteId,
      variables: {
        siteId: 'master',
      },
      fetchPolicy: 'network-only',
    })
      .then(({ data }) => {
        // console.log("getSubscriptionPlansBySiteId ::",data)
        if (data && data.getSubscriptionPlansBySiteId) {
          let allPlan = data.getSubscriptionPlansBySiteId
          let platform = 'BOOKING_RESTAURANTS'
          let sort = allPlan.filter(function (hero) {
            return (platform ? (hero.siteType == 'O2OCommerce' && hero.platform == 'BOOKING_RESTAURANTS') : hero.siteType == 'O2OCommerce')
          })
          let fData = sort.sort((a, b) => a.planName.localeCompare(b.planName))
          setallPlan(fData)
          // this.siteOptionPlanFilter(data.getSubscriptionPlansBySiteId,this.state.siteType,this.state.siteOption)
          // this.setState({
          //     allPlan: data.getSubscriptionPlansBySiteId
          // })

        }
      })
  }

  const getTimeStamp = (fromDate, toDate) => {
    // console.log("getTimeStamp ::",fromDate, toDate)
    fromDate.set({ hour: 0, minute: 1, second: 0, millisecond: 0 });
    toDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

    const startTimestamp = Math.floor(fromDate.valueOf() / 1000);
    const endTimestamp = Math.floor(toDate.valueOf() / 1000);
    // console.log("{startTimestamp, endTimestamp} :::", {startTimestamp, endTimestamp})
    return { startTimestamp, endTimestamp };
  }

  const getUsers = () => {
    return new Promise((resolve, reject) => {
      props.client.query({
        query: getInternalUsers,
        fetchPolicy: 'cache-first'
      })
        .then(({ data }) => {
          resolve(data.getInternalUsers)
        })
    })
  }

  const supportAccMChange = (value) => {
    setsupportAccM(value)
  }

  const handleSearchPlanName = (Data) =>{
    let val = Data.target.value
    setPlaneNameSearch(val)
  }

  const onMonthChange = (value) => {
    //  console.log("onMonthChange::",value)

    let month = moment(value).month()
    // console.log("month:::",month)
    setmonth(month)
    setMonthValue(value)
  }

  const salesPersonChange = (value) => {
    setsalesPerson(value)
  }

  const partnerTypeChange = (value) => {
    setpartnerType(value)
  }

  const partnerNameChange = (value) => {
    setpartnerName(value)
  }

  const companyNameChange = (e) => {
    setcompanyName(e.target.value)
  }


  const getPreviousMonthData = async (month, currentMonthCount) => {

    const currentDate = moment().month(month).startOf('month');
    let tempDump;

    for (let i = 1; i <= 10; i++) {
      // console.log(currentDate.format('YYYY-MM-DD'));
      tempDump = await checkDumpForPreviousMonths(currentDate.format('YYYY-MM-DD'))
      // console.log("tempDump:::",tempDump)
      if (tempDump && tempDump.getInternalDump && tempDump.getInternalDump.dumpData && tempDump.getInternalDump.dumpData != '[]') {
        break;
      }
      currentDate.add(1, 'day');
    }
    // console.log("dump:::",dump)
    if (tempDump && tempDump.getInternalDump && tempDump.getInternalDump.dumpData && tempDump.getInternalDump.dumpData != '[]') {
      let sdf = JSON.parse(tempDump.getInternalDump.dumpData)
      return sdf;
    } else {
      return []
    }

  }

  const handleFilter = async () => {
    let filterData = allrenewalRecord
    let final = []
    setfilterloading(true)
    setLoading(true)
    // filterData.map((ele)=>{
    //     let companyNameSlug = ele.companyName.toLowerCase()
    //     if(supportAccM && ele.subscriptionPlans && ele.subscriptionPlans.length > 0 && ele.subscriptionPlans.some((stype) => stype.planName ==  supportAccM)){
    //         final.push(ele)
    //     }
    //     if(month  && ele.endDate){
    //       let dataMonth = moment(ele.endDate).month()
    //       if(month === dataMonth){
    //         final.push(ele)
    //       }
    //     }
    //     if(salesPerson && ele.salesPerson == salesPerson){
    //         final.push(ele)
    //     }
    //     if((partnerType && ele.partnerType == partnerType) || (partnerName && ele.channelPartner == partnerName)){
    //         final.push(ele)
    //     }
    //     if(companyName && companyNameSlug.includes(companyName.toLowerCase())){
    //         final.push(ele)
    //     }
    // })

    if (month) {
      const currentMonthCount = moment().month()
      if (month < currentMonthCount) {
        filterData = await getPreviousMonthData(month, currentMonthCount);
        // console.log("filterData:::",filterData)
      }

      if (month > (currentMonthCount)) {

        const currentDate = moment().month(month).startOf('month');
        const pastDate = moment().month(month).subtract(1, "month");
        const toDate = moment().month(month).add(1, "month");

        let respDate = getTimeStamp(pastDate, currentDate)
        let variables = {
          "fromDate": respDate.endTimestamp,
          "toDate": toDate.format('YYYY-MM-DD'),
          "pastDate": respDate.startTimestamp,
        }

        let record = await getRenewalData(variables)
        if (record.result) {
          let json = JSON.parse(record.result)
          // console.log("json", json)
          filterData = json
        }
      }
    }

    if (supportAccM && filterData && filterData.length > 0) {
      for (let i = 0; i < filterData.length; i++) {
        if (filterData[i].subscriptionPlans && filterData[i].subscriptionPlans.length > 0) {
          for (let j = 0; j < filterData[i].subscriptionPlans.length; j++) {
            if (filterData[i].subscriptionPlans[j].planName && filterData[i].subscriptionPlans[j].planName.includes(supportAccM)) {
              final.push(filterData[i])
            }
          }
        }
      }
    } else {
      final = filterData
    }

    if(planeNameSearch){
       final = []
      filterData = JSON.parse(JSON.stringify(filterData))
      for (let i = 0; i < filterData.length; i++) {
        if (filterData[i].subscriptionPlans && filterData[i].subscriptionPlans.length > 0) {
          for (let j = 0; j < filterData[i].subscriptionPlans.length; j++) {
         
            if (filterData[i].subscriptionPlans[j].planName ) {
               let planeName = filterData[i].subscriptionPlans[j].planName.toLowerCase() 
               let seachName = planeNameSearch.toLowerCase()
              if(planeName.includes(seachName)){
                final.push(filterData[i])
              }
            }
          }
        }
      }

    }

    if (month) {
      let arr = []
      for (let i = 0; i < final.length; i++) {
        if (final[i].endDate) {
          let dataMonth = moment(final[i].endDate).month()
          if (month === dataMonth) {
            arr.push(final[i])
          }
        }
      }
      final = arr
    }

    if (partnerType && partnerName) {
      let aar = final.filter((x) => x.partnerType == partnerType && x.partnerName == partnerName)
      final = aar
    }
    if (companyName) {
      let aar = final.filter((x) => x.companyName == companyName)
      final = aar
    }
    // console.log("final",final)

    let TotalSubscriptionAmonut = 0;
      for(let i = 0;i<final.length;i++){
        let amount = final[i].subscriptionPlans && final[i].subscriptionPlans.length > 0 ?  final[i].subscriptionPlans[0].amount : 0;
        TotalSubscriptionAmonut = TotalSubscriptionAmonut + amount
      }
     
      let obj = {
        subscriptionPlans : [{  
            "amount": TotalSubscriptionAmonut
        }]
      }
    
    if(final && final.length > 0){  
        final.push(obj)
    }

    setrenewalRecord(final)
    setfilterloading(false)
    setLoading(false)

  }

  const resetFilter = () => {
    setsupportAccM(undefined)
    setsalesPerson(undefined)
    setpartnerType(undefined)
    setpartnerName(undefined)
    setcompanyName(undefined)
    setmonth(undefined)
    setMonthValue(undefined)
    setPlaneNameSearch(undefined)

    let allrecord = JSON.parse(JSON.stringify(allrenewalRecord))

    let TotalSubscriptionAmonut = 0;
      for(let i = 0;i<allrecord.length;i++){
        let amount = allrecord[i].subscriptionPlans && allrecord[i].subscriptionPlans.length > 0 ?  allrecord[i].subscriptionPlans[0].amount : 0;
        TotalSubscriptionAmonut = TotalSubscriptionAmonut + amount
      }
     
      let obj = {
        subscriptionPlans : [{  
            "amount": TotalSubscriptionAmonut
        }]
      }

      if(allrecord && allrecord.length > 0){
        allrecord.push(obj)
      }



    setrenewalRecord(allrecord)
    
  }

  const openNotesModal = (data) => {
    setvisible(true)
    setsiteData(data)
  }

  const closeNotesModal = () => {
    setvisible(false)
  }

  const disableFutureMonths = (current) => {
    return current && current > moment().add(1, 'month').endOf('month'); // Disable months after the next month
  };

  const columns = [
    {
      title: 'Seq No.',
      dataIndex: 'seqNo',
      key: 'seq No',
      width: 70,
      fixed: 'left',
      render: (text, record, index) => (
         record.companyName && <span>{ record.seqNo}</span>  // Adding 1 to make it 1-based index
      )
    },
    {
      title: 'Business Name',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 200,
      fixed: 'left',
      // render:(data)=>{
      //   return(
      //     <span>{this.truncateString(data,40)}</span>
      //   )
      // }
    },
    {
      title: 'Sales Person',
      dataIndex: 'salesPerson',
      key: 'salesPerson',
      width: 100,
    },
    // {
    //     title: 'Partner Name',
    //     dataIndex: 'channelPartner',
    //     key: 'channelPartner',
    //     width: 100,
    //   },
    // {
    //     title: 'Support Act Manager',
    //     dataIndex: 'supportAccManager',
    //     key: 'supportAccManager',
    //     width: 100,
    //   },
    {
      title: 'Contact Number',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      width: 100,
      render: (contactNumber) => {
        // let contactNumber = data.settingData && data.settingData.contactNumber ? data.settingData.contactNumber : ''
        return (
          <span>{contactNumber}</span>
        )
      }
    },
    // {
    //   title: 'Contact Person',
    //   key: 'companyName',
    //   width: 100,
    //   render:(data)=>{
    //     let companyName = data.settingData && data.settingData.companyName ? data.settingData.companyName: ''
    //     return(
    //       <span>{companyName}</span>
    //     )
    //   }
    // },
    {
      title: 'Plan Name',
      dataIndex: 'planName',
      key: 'planName',
      width: 100,
    },
    {
      title: 'Sub Amt',
      // dataIndex: 'charges',
      key: 'charges',
      width: 100,
      render: (data) => {
        return (
          // data.settingData ?
          //   <span>{returnSymbol(parseFloat(charges), data.settingData.curr)}</span> : <span>{returnSymbol(parseFloat(charges), "INR")}</span>
          <span>{returnSymbol(parseFloat(data.charges ? data.charges / 100 : 0), data.curr ? data.curr : "INR")}</span>
        )
      }
    },

    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      sorter: (a, b) => {
        return a.createdAt - b.createdAt
      },
      render: (data) => {
        return (
          <div>
            {data != null &&
              moment.unix(data).format("DD-MM-YYYY")
            }
            {data == null && <span></span>}
          </div>
        )
      }
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      sorter: (a, b) => {
        return new Date(a.endDate) - new Date(b.endDate)
      }
    }
  ]

  betweenDates && betweenDates.map((ele, i) => {
    let obj = {
      title: ele,
      // dataIndex: 'createdAt',
      key: ele,
      width: 80,
      align: 'center',
      render: (data) => {

        if (data && data.ordersCount && data.ordersCount.length > 0) {
          let orderCount = null
          data.ordersCount.map((val) => {
            if (val.date == ele) {
              orderCount = val.totalOrders
            }
          })

          return {
            props: {
              style: { background: orderCount ? "#afdb8b" : "#f4bfc0" }
            },
            children: <span>{orderCount ? orderCount : '0'}</span>
          }
        } else {
          return {
            props: {
              style: { background: "#f4bfc0" }
            },
            children: <span>{'0'}</span>
          }
        }
      }
    }
    columns.push(obj)
  })

  let note = {
    title: 'Notes',
    key: 'notes',
    width: 80,
    fixed: 'right',
    align: 'center',
    render: (data) => {
      return (
        <Icon type='form' onClick={() => openNotesModal(data)} />
      )
    }
  }

  columns.push(note)

  return (
    <App header={"Renewal Dashboard"}>
      <Row gutter={24} style={{marginBottom:"10px"}}>  
        <Col md={{ span: 18 }}>
        <Row gutter={24}>
        <Col md={{ span: 6 }}>
          <Select
            style={{ width: '100%', marginRight: '10px' }}
            onChange={supportAccMChange}
            value={supportAccM}
            allowClear
            showSearch
            placeholder="Site Plan">
            {allPlan && allPlan.length > 0 ?
              allPlan.map((c) => {
                return (
                  <Select.Option key={c.planName} value={c.planName}>
                    <div style={{ whiteSpace: 'normal' }}>{c.planName}</div>
                  </Select.Option>
                )
              }) : ''}
          </Select>
        </Col>
        <Col md={{ span: 6 }}>
          <MonthPicker onChange={onMonthChange}  value={monthValue} placeholder="Select month" style={{ width: '100%', marginRight: '10px' }} />
        </Col>
        <Col md={{ span: 6 }}>
          <Select
            optionFilterProp="children"
            style={{ width: '100%', marginRight: '10px' }}
            onChange={partnerTypeChange}
            value={partnerType}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
            showSearch
            placeholder="Partner Type">
            <Select.Option key={'cp'} value={'CP'} >CP</Select.Option>
            <Select.Option key={'reseller'} value={'RESELLER'} >Reseller</Select.Option>
            <Select.Option key={'referral'} value={'REFERRAL'} >Referral</Select.Option>
            <Select.Option key={'stockist'} value={'STOCKIST'} >Stockist</Select.Option>
          </Select>
        </Col>
        <Col md={{ span: 6 }}>
          <Select
            optionFilterProp="children"
            style={{ width: '100%', marginRight: '10px' }}
            onChange={partnerNameChange}
            value={partnerName}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            allowClear
            showSearch
            placeholder="Partner Name">
            {InternalUserData && InternalUserData.length > 0 ?
              InternalUserData.map((ele) => {
                if (partnerType == 'CP') {
                  if (ele.companyName) {
                    return (
                      <Select.Option key={ele.companyName} value={ele.companyName} >{ele.companyName}</Select.Option>
                    )
                  }
                }
                else {
                  return (
                    <Select.Option key={ele.name} value={ele.name} >{ele.name}</Select.Option>
                  )
                }
              }) : ''}
          </Select>
        </Col>
        <Col md={{ span: 6 }} style={{marginTop:"10px"}}>
          <Input placeholder="Business Name" value={companyName} onChange={companyNameChange} style={{ width: '100%', marginRight: '10px' }} />
        </Col>
        <Col md={{ span: 6 }} style={{marginTop:"10px"}}>
          <Input placeholder="Search With Plan Name" value={planeNameSearch} onChange={handleSearchPlanName} style={{ width: '100%', marginRight: '10px' }} />
        </Col>
        </Row>
        </Col>
        <Col md={{ span: 6 }}>
        <Row>
        <Col md={{ span: 24 }}>
          <Button type='danger' style={{ float: "right", marginBottom: 16, cursor: "pointer", marginLeft: '10px', width: 60 }} onClick={resetFilter}>Reset</Button>

          <Button type='primary' style={{ float: "right", marginBottom: 16, cursor: "pointer", width: 70 }} loading={filterloading} onClick={handleFilter} >Search</Button>
        </Col>
        </Row>
        </Col>
      </Row>
      <div id='custom-make-ant-table-scrollable'>
        <Table
          columns={columns}
          className='sitetable'
          bordered
          dataSource={renewalRecord}
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{ y: 800, x: '110%' }}
          pagination={{ pageSize: 100 }}
        />
      </div>
      <Modal
        title={"Notes"}
        visible={visible}
        onCancel={closeNotesModal}
        footer={null}
        destroyOnClose={true}
        width={1200}>
        <SiteLogs siteDetails={siteData} />
      </Modal>
    </App>
  )

}
export default withApollo(RenewalDashboard)